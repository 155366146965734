import React from "react"

import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import Layout from "../../components/layout"
import Seo from "../../components/seo"

const Page = () => (
  <Layout>
    <Seo title="Lister, underlister og seksjoner" />
    <div className="longtext">
      <h2>Lister, underlister og seksjoner</h2>

      <p>
        Aller først; vi anbefaler at du ser videoene som demonstrer ulik bruk av
        lister på <Link to="/">https://www.mente.app</Link> om du ikke har gjort
        det allerede.
      </p>

      <p>
        I Mente kan du lage både lister og seksjoner for å organisere oppgaver
        og lister på måten som passer deg. Lister og seksjoner ligner på
        hverandre, men oppfører seg på litt ulik måte:
      </p>

      <h3>Eksempel</h3>
      <p>
        Bildet under viser en liste kalt <b>Hjem</b>, som har to seksjoner (
        <b>Kudos</b> og <b>Jevnlig</b>) og tre underlister (<b>Oppussing</b>,{" "}
        <b>Bilen</b> og <b>Vedlikehold</b>). Legg merke til listeikonet bak
        underlistene, som forteller at det er en underliste og ikke en seksjon.
      </p>

      <StaticImage
        src="../../images/underliste-seksjoner.png"
        alt="Liste med underlister og seksjoner"
      />

      <h3>Seksjoner</h3>
      <ul>
        <li>
          er en "intern" oppdeling av en liste som du kan bruke for å gjøre den
          mer oversiktlig
        </li>
        <li>hører alltid til en liste (Hjem i eksemplet over)</li>
        <li>synes ikke i listeoversikten</li>
        <li>
          blir ikke gått gjennom som et eget steg i Ukentlig gjennomgang, men
          som en del av listen den hører til
        </li>
        <li>
          kan ikke deles med andre direkte, men blir automatisk delt når du
          deler listen de hører til
        </li>
      </ul>

      <h4>For å opprette en seksjon:</h4>
      <p>
        Dra den grønne plussknappen mot venstre kant av listen og slipp når
        "Legg til seksjon" dukker opp:
      </p>
      <StaticImage
        src="../../images/seksjon.png"
        alt="Legg til seksjoner ved å dra den grønne pluss knappen"
      />

      <h3>Lister (og underlister):</h3>
      <ul>
        <li>kan ha både seksjoner og andre lister under seg (underlister)</li>
        <li>
          kan deles med andre og vil i så fall dele eventuelle seksjoner
          automatisk. Underlister blir ikke delt automatisk, men du kan gå inn
          på underlistene og dele de også om ønskelig
        </li>
        <li>er alltid en del av Ukentlig gjennomgang</li>
      </ul>
    </div>
  </Layout>
)

export default Page
